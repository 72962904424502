import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { motion } from 'framer-motion'

import { useLocale, useTranslation, useInitialReveal, dateRangeToYears } from '../utils'

import SEO from './seo'
import GalleryCard from './GalleryCard'
import Icon from './Icon'
import BackText from './BackText'
import TypeAnimation from './TypeAnimation'
import HeadingLine from './HeadingLine'
import ProjectsHeaderBackground from './ProjectsHeaderBackground'
import LazyImg from './LazyImg'
import AnimateReveal from './AnimateReveal'

import projects from '../data/projects'
import collaborators from '../data/collaborators'

const HERO_REVEAL_DURATION = 0.5

const bttReveal = {
  hidden: {
    opacity: 0,
    y: 24
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: HERO_REVEAL_DURATION,
      ease: 'easeOut'
    }
  },
}

const groupReveal = {
  hidden: {
    transition: {}
  },
  visible: {
    transition: {
      when: 'afterChildren',
      staggerChildren: 0.2,
    }
  }
}

const rtlReveal = {
  hidden: {
    opacity: 0,
    x: 100
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: HERO_REVEAL_DURATION,
      ease: 'easeOut'
    }
  },
}

const heroTechs = [
  {
    text: 'Vue.js'
  },
  {
    text: 'React'
  },
  {
    text: 'GraphQL'
  },
  {
    text: 'NodeJS'
  },
  {
    text: 'Wordpress'
  },
  {
    text: 'Meteor'
  }
];

const Home = () => {
  const reveal = useInitialReveal()

  const { homeUrl } = useLocale()
  const t = useTranslation()

  const getCollaboratorProp = (collaboratorKey, prop) => (
    collaborators[collaboratorKey] &&
    collaborators[collaboratorKey][prop]
  ) || '#'

  return (
    <>
      <SEO />
      <header className="c-hero  c-section">
        <div className="c-section__align c-section__align--right">
          <div className="c-hero__block  c-section__block c-section__block--large c-section__block--fill">
            <div className="c-hero__wrapper  c-section__wrapper">
              <LazyImg
                component={motion.img}
                src={'../../images/simon-martineau.png'}
                alt="Simon Martineau"
                className="c-hero__image"
                initial="hidden"
                animate={reveal ? 'visible' : 'hidden'}
                variants={rtlReveal}
              />
              <motion.div
                className="c-hero__content  c-section__content"
                initial="hidden"
                animate={reveal ? 'visible' : 'hidden'}
                variants={groupReveal}
              >
                <BackText className="c-hero__back-text  c-section__block-back-text">Simon<br />Martineau</BackText>
                <motion.img
                  src={'../../images/simon-martineau-logo-white.svg'}
                  alt="Simon Martineau" className="c-hero__logo"
                  variants={bttReveal}
                />
                <div
                  className="c-hero__lead"
                >
                  <motion.h1
                    className="c-hero__heading  t-heading"
                    variants={bttReveal}
                  >
                    {t('home.hero.heading', {
                      skill: <TypeAnimation key="skill" className="c-skill" values={heroTechs} />
                    })}
                  </motion.h1>
                  <motion.p
                    className="c-hero__subheading  t-subheading"
                    variants={bttReveal}
                  >
                    {t('home.hero.subheading')}
                  </motion.p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </header>
      <section className="c-section c-section--background">
        <div className="c-section__align">
          <div className="c-section__wrapper">
            <AnimateReveal>
              <motion.div
                className="c-section__content"
                variants={groupReveal}
              >
                <BackText parallaxOffset={400}>{t('home.about.heading')}</BackText>
                <motion.h2
                  id={t('home.about.id')}
                  className="c-about__heading  c-section-heading c-section-heading--back-text"
                  variants={bttReveal}
                >
                  {t('home.about.heading')}
                  <HeadingLine/>
                  <span className="c-about__marker  marker marker--far-right">
                    <span className="c-about__marker-dot  marker__dot"></span>
                  </span>
                </motion.h2>
                <motion.div
                  className="ctx-content"
                  variants={bttReveal}
                >
                  {t('home.about.text', {
                    years: dateRangeToYears(new Date('2015-05-15'), new Date()),
                  })}
                </motion.div>
                <motion.p
                  className="c-about__cta"
                  variants={bttReveal}
                >
                  <AnchorLink to={`${homeUrl}#${t('home.network.id')}`} className="c-button">{t('home.about.linkText')}</AnchorLink>
                </motion.p>
              </motion.div>
            </AnimateReveal>
          </div>
        </div>
      </section>
      <section id={t('home.projects.id')} className="c-projects  c-section">
        <div className="c-section__align">
          <div className="c-projects__block  c-section__block c-section__block--fill">
            <ProjectsHeaderBackground />
            <div className="c-section__wrapper">
              <div className="c-projects__block-content  c-section__content">
                <BackText className="c-projects__back-text" parallaxOffset={200}>{t('home.projects.heading')}</BackText>
              </div>
            </div>
          </div>
          <AnimateReveal>
            <motion.div
              className="c-section__wrapper"
              variants={groupReveal}
            >
              <div className="c-projects__content  c-section__content c-section__content--large">
                <div className="c-projects__content-wrapper">
                  <span className="c-projects__marker  marker marker--left">
                    <span className="c-projects__marker-line-x  marker__line-x"></span>
                    <span className="c-projects__marker-dot  marker__dot"></span>
                  </span>
                  <motion.h2
                    className="c-projects__heading  c-section-heading"
                    variants={bttReveal}
                  >
                    {t('home.projects.heading')}
                    <HeadingLine/>
                  </motion.h2>
                  <div className="c-projects__gallery  c-gallery">
                    <div className="c-gallery__wrapper">
                      {projects.map(project => (
                        <div className="c-gallery__item" key={project.name}>
                          <GalleryCard
                            tag={motion.article}
                            imageSrc={project.image}
                            title={project.name}
                            link={project.link}
                            collaborator={project.collaborator}
                            collaboratorLink={getCollaboratorProp(project.collaborator, 'link')}
                            collaboratorName={getCollaboratorProp(project.collaborator, 'name')}
                            tags={project.tags}
                            variants={bttReveal}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </AnimateReveal>
        </div>
      </section>
      <section className="c-network c-section c-section--padded-top">
        <div className="c-section__align c-section__align--right">
          <div className="c-section__block c-section__block--large c-section__block--fill c-section__block--padded">
            <div className="c-network__wrapper  c-section__wrapper">
              <AnimateReveal>
                <motion.div
                  className="c-section__content"
                  variants={groupReveal}
                >
                  <BackText className="c-section__block-back-text" parallaxOffset={200}>{t('home.network.heading')}</BackText>
                  <motion.h2
                    id={t('home.network.id')}
                    className="c-section-heading c-section-heading--back-text c-section-heading--light"
                    variants={bttReveal}
                  >
                    {t('home.network.heading')}
                    <HeadingLine/>
                  </motion.h2>
                  <div className="c-network__content-wrapper">
                    <span className="c-network__marker  marker marker--right">
                      <span className="c-network__marker-line-x  marker__line-x"></span>
                      <span className="c-network__marker-dot  marker__dot"></span>
                    </span>
                    <div className="ctx-content">
                      <motion.div variants={bttReveal}>
                        {t('home.network.text')}
                      </motion.div>
                      <ul className="c-inline-list  t-large-thick">
                        {Object.entries(collaborators).map(([key, collaborator]) => (
                          <motion.li
                            className="c-inline-list__item"
                            key={key}
                            variants={bttReveal}
                          >
                            <a href={collaborator.link} className="u-plain-link">
                              {collaborator.name}
                            </a>
                          </motion.li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="c-network__cta">
                    <motion.p
                      className="c-network__cta-text"
                      variants={bttReveal}
                    >
                      {t('home.network.ctaText')}
                    </motion.p>
                    <motion.ul
                      className="c-network__cta-actions  c-inline-list"
                      variants={bttReveal}
                    >
                      <li className="c-inline-list__item">
                        <a href="mailto:contact@simon-martineau.com" className="c-button c-button--light">{t('home.network.ctaActionText')}</a>
                      </li>
                      <li className="c-inline-list__item">
                        <a href="https://www.linkedin.com/in/simon-martineau-dev" className="c-icon-button c-icon-button--light" title="LinkedIn"><Icon fa={['fab', 'linkedin-in']}/></a>
                      </li>
                      <li className="c-inline-list__item">
                        <a href="https://github.com/marsimeau" className="c-icon-button c-icon-button--light" title="Facebook"><Icon fa={['fab', 'github']}/></a>
                      </li>
                    </motion.ul>
                  </div>
                </motion.div>
              </AnimateReveal>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home
