export default {
  '2associes': {
    name: '2 Associes',
    link: 'https://2associes.com/'
  },
  mlajeunesse: {
    name: 'Mathieu Lajeunesse',
    link: 'https://www.facebook.com/mathieulajeunessemediasinteractifs/'
  },
}
