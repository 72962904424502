import { useState, useRef, useLayoutEffect, cloneElement, Children } from 'react'
import { useViewportScroll } from 'framer-motion'
import PropTypes from 'prop-types'
import { useEventListener } from '../utils'

const AnimateReveal = ({ offset, ready, children }) => {
  const [variant, setVariant] = useState('hidden')

  const elements = Children.toArray(children)
  const element = elements[0]

  const localRef = useRef()
  const ref = element.ref || localRef

  const positionRef = useRef({})

  const { scrollY } = useViewportScroll()

  const getOffset = () => {
    if (typeof offset === 'string') {
      if (/\d?%/.test(offset)) {
        const screenRatio = parseInt(offset, 10) / 100

        return positionRef.current.screenHeight * screenRatio;
      }

      return 0
    }

    return offset
  }

  const revealOnPosition = (scrollTop) => {
    if (scrollTop >= positionRef.current.top - getOffset()) {
      setVariant('visible')
    }
  }

  scrollY.onChange((y) => {
    if (variant !== 'visible') {
      revealOnPosition(y)
    }
  })

  const handleViewportResize = () => {
    if (ready) {
      const { top } = ref.current.getBoundingClientRect() || {}

      positionRef.current.top = top + window.scrollY
      positionRef.current.screenHeight = window.innerHeight

      revealOnPosition(window.scrollY)
    }
  }

  useLayoutEffect(() => {
    handleViewportResize()
  }, [ready])

  useEventListener(global.window, 'resize', handleViewportResize)
  useEventListener(global.window, 'orientationchange', handleViewportResize)

  return cloneElement(element, { ref, animate: variant, initial: 'hidden' })
}

AnimateReveal.defaultProps = {
  offset: '40%',
  ready: true,
}

AnimateReveal.propTypes = {
  offset: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  ready: PropTypes.bool,
}

export default AnimateReveal
