import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * Icon.
 * Supply either a FontAwesome icon as a prop or a material icon as a child.
 *
 * @param {string[]|Object} props.fa - FontAwesomeIcon icon prop 
 * @param {Array} props.children - Material icon text
 */
const Icon = ({ className, fa, children, ...attrs }) => {
  const icon = () => {
    if (fa) {
      return <FontAwesomeIcon className="" icon={fa} />
    } else {
      return children
    }
  }

  return (
    <span
      className={classNames(className, 'icon', { 'icon--material': !fa })}
      aria-hidden="true"
      {...attrs}
    >{icon()}</span>
  )
}

Icon.defaultProps = {
  fa: null,
}

export default Icon
