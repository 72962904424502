import React, { useRef, useState, useLayoutEffect } from 'react'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import { useEventListener } from '../utils'

import LazyImg from './LazyImg'

const velocity = 0.6

const ProjectsHeaderBackground = ({ parallaxOffset }) => {
  const [elementTop, setElementTop] = useState(0)

  const start = elementTop - parallaxOffset

  const ref = useRef()

  const { scrollY } = useViewportScroll()
  const translateY = useTransform(scrollY, [start, start + 1], [0, velocity], {
    clamp: false
  })

  const handleViewportResize = () => {
    if (global.window) {
      const { top } = ref.current.getBoundingClientRect() || {}

      setElementTop(top + window.scrollY)
    }
  }

  useLayoutEffect(() => {
    handleViewportResize()
  }, [])

  useEventListener(global.window, 'resize', handleViewportResize)
  useEventListener(global.window, 'orientationchange', handleViewportResize)

  return (
    <picture ref={ref} className="c-projects__header-picture">
      <source media="(min-width: 576px)" srcSet={'../../images/frames.jpg'} />
      <source media="(min-width: 0)" srcSet={'../../images/frames-1160.jpg'} />
      <LazyImg component={motion.img} src={'../../images/frames-1160.jpg'} className="c-projects__header-image" alt="" style={{ translateY }} />
    </picture>
  )
}

ProjectsHeaderBackground.defaultProps = {
  parallaxOffset: 0
}

export default ProjectsHeaderBackground