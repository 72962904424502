export default [
  {
    name: 'Fizik',
    link: 'https://www.fizik.ca',
    collaborator: '2associes',
    image: '../../images/projects/fizik.jpg',
    tags: [
      'fullstack',
      'integration',
    ]
  },
  {
    name: 'Vesta',
    link: 'https://vestamtl.com/',
    collaborator: 'mlajeunesse',
    image: '../../images/projects/vesta.jpg',
    tags: [
      'wordpress',
      'integration',
    ]
  },
  {
    name: 'Théatre du Vieux\u2011Terrebonne',
    link: 'https://theatreduvieuxterrebonne.com/',
    collaborator: 'mlajeunesse',
    image: '../../images/projects/theatre-du-vieux-terrebonne.jpg',
    tags: [
      'wordpress',
      'integration',
    ]
  },
  {
    name: 'Gourmet Sauvage',
    link: 'https://gourmetsauvage.ca/',
    collaborator: 'mlajeunesse',
    image: '../../images/projects/gourmet-sauvage.jpg',
    tags: [
      'wordpress',
      'integration',
    ]
  },
  {
    name: 'Jean-Pascal Lemire',
    link: 'https://www.jeanpascallemire.com/',
    collaborator: 'mlajeunesse',
    image: '../../images/projects/jean-pascal-lemire.jpg',
    tags: [
      'wordpress',
      'integration',
    ]
  },
  {
    name: 'Raccoursci',
    link: 'https://www.raccoursci.com/',
    collaborator: 'mlajeunesse',
    image: '../../images/projects/raccoursci.jpg',
    tags: [
      'integration',
    ]
  },
  {
    name: '2 Associes',
    link: 'https://2associes.com/',
    collaborator: '2associes',
    image: '../../images/projects/2-associes.jpg',
    tags: [
      'wordpress',
      'integration',
    ]
  },
]
