import React, { useState, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { usePrevious } from '../utils'

const TypeAnimation = ({ className, initialDelay, erasingInterval, values }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [typing, setTyping] = useState(false)

  const currentValue = values[currentIndex] || {}
  const previousValue = usePrevious(currentValue)

  const [text, setText] = useState(currentValue.text)

  const textRef = useRef(text);
  const initialized = useRef(false);
  const currentInterval = useRef();

  const incrementIndex = () => {
    if (values[currentIndex + 1]) {
      setCurrentIndex(currentIndex + 1);
    } else if (values.length > 1) {
      setCurrentIndex(0);
    }
  }

  const startTyping = () => {
    const intervalDuration = (currentValue.duration || 700) / (currentValue.text.length);

    currentInterval.current = setInterval(() => {
      const newText = currentValue.text.slice(0, textRef.current.length + 1)

      setText(newText)

      if (newText.length === currentValue.text.length) {
        setTyping(false);

        clearInterval(currentInterval.current)
        setTimeout(() => incrementIndex(), currentValue.wait || 3000)
      }
    }, intervalDuration)
  }

  const startErasing = () => {
    setTyping(true)

    currentInterval.current = setInterval(() => {
      const newText = previousValue.text.slice(0, textRef.current.length - 1)

      setText(newText)

      if (newText.length === 0) {
        clearInterval(currentInterval.current)
        startTyping()
      }
    }, erasingInterval)
  }

  useLayoutEffect(() => {
    textRef.current = text;
  }, [text])

  useLayoutEffect(() => {
    if (initialized.current) {
      if (currentValue.text) {
        startErasing()
      }
    } else {
      initialized.current = true;

      setTimeout(() => {
        incrementIndex();
      }, initialDelay)
    }

    return () => {
      clearInterval(currentInterval.current)
    }
  }, [currentIndex])

  return (
    <span
      className={classnames(className, 'c-type-animation', { 'is-typing': typing })}
      title={currentValue.text}
    >{text}</span>
  )
}

TypeAnimation.propTypes = {
  className: PropTypes.string,
  initialDelay: PropTypes.number,
  erasingInterval: PropTypes.number,
  values: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    duration: PropTypes.number,
    wait: PropTypes.number,
  })),
}

TypeAnimation.defaultProps = {
  initialDelay: 3000,
  erasingInterval: 75,
  values: [],
}

export default TypeAnimation
