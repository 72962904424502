import React, { useState, useRef, useEffect, forwardRef } from 'react'
import classnames from 'classnames'

const LazyImg = forwardRef(({ className, component: Img, ...attrs }, ref) => {
  const [loaded, setLoaded] = useState(false)

  const localRef = useRef()
  const imgRef = ref || localRef

  useEffect(() => {
    const loader = document.createElement('img')

    loader.onload = () => setLoaded(true)
    loader.src = imgRef.current.currentSrc || imgRef.current.src

    return () => {
      delete loader.onload
      setLoaded(false)
    }
  }, [])

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Img
      ref={imgRef}
      className={classnames(className, 'c-lazy-img', { 'is-loading': !loaded })}
      {...attrs}
    />
  )
})

LazyImg.defaultProps = {
  className: '',
  component: 'img',
}

export default LazyImg
