import React, { useState } from 'react'
import classnames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'

import { checkKeyCode, useTranslation } from '../utils'
import Icon from './Icon'
import LazyImg from './LazyImg'

const cardVariants = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.2,
      when: 'afterChildren',
    }
  }
}

const infoVariants = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.2,
      ease: 'easeOut'
    }
  },
  hidden: {
    opacity: 0,
    x: -12,
    transition: {
      duration: 0.2,
      ease: 'easeOut'
    }
  }
}

const tagListVariants = {
  visible: {
    x: 0,
    transition: {
      duration: 0.2,
      ease: 'easeOut',
      delayChildren: 0.3,
      staggerChildren: 0.1
    }
  },
  hidden: {
    x: -12,
    transition: {
      duration: 0.2,
      ease: 'easeOut'
    }
  }
}

const tagVariants = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: 'easeOut'
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: 'easeOut'
    }
  }
}

const GalleryCard = ({
  tag: Tag,
  imageSrc,
  title,
  link,
  collaboratorLink,
  collaboratorName,
  tags,
  ...attrs
}) => {
  const [active, setActive] = useState(false)

  const t = useTranslation()

  const handleCardClick = () => {
    setActive(true)
  }

  const handleCardKeydown = (event) => {
    if (checkKeyCode(event, 'Enter', 'Space')) {
      setActive(true)
    }
  }

  const handleContentClick = (event) =>{
    if (active) {
      event.stopPropagation()
    }
  }

  const handleCloseClick = () => {
    setActive(false)
  }

  return (
    <Tag
      className={classnames('c-gallery-card', { 'is-active': active })}
      onClick={handleCardClick}
      onKeyDown={handleCardKeydown}
      tabIndex="0"
      role="button"
      aria-pressed={active ? 'true' : 'false'}
      {...attrs}
    >
      {imageSrc && (
        <LazyImg src={imageSrc} alt={title} className="c-gallery-card__image"/>
      )}
      <AnimatePresence>
        {active && (
          <motion.div
            className="c-gallery-card__content"
            onClick={handleContentClick}
            initial={'hidden'}
            animate={'visible'}
            exit={'hidden'}
            variants={cardVariants}
          >
            <button
              type="button"
              className="c-gallery-card__close"
              onClick={handleCloseClick}
              title={t('projects.close')}
            >
              <Icon className="c-gallery-card__close-icon">close</Icon>
            </button>
            <div className="c-gallery-card__info">
              <motion.h3
                className="c-gallery-card__name"
                variants={infoVariants}
              >
                <a
                  href={link}
                  className="c-gallery-card__link"
                >
                  {title}
                </a>
              </motion.h3>
              <p className="c-gallery-card__collaboration">
                <motion.span
                  className="c-gallery-card__collaboration-label"
                  variants={infoVariants}
                >
                  {t('projects.collaborationWith')}
                </motion.span>
                <motion.span
                  className="c-gallery-card__collaboration-name"
                  variants={infoVariants}
                >
                  <a
                    href={collaboratorLink}
                    className="c-gallery-card__collaboration-link"
                  >
                    {collaboratorName}
                  </a>
                </motion.span>
              </p>
              <motion.ul
                className="c-gallery-card__tags"
                variants={tagListVariants}
              >
                {tags.map(tag => (
                  <motion.li
                    className="c-gallery-card__tags-item"
                    key={tag}
                    variants={tagVariants}
                  >
                    <span className="span c-gallery-card__tag">{t(`projects.tags.${tag}`)}</span>
                  </motion.li>
                ))}
              </motion.ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Tag>
  )
}

GalleryCard.defaultProps = {
  tag: 'div',
  imageSrc: '',
  title: '',
  link: '#',
  collaboratorLink: '#',
  collaboratorName: '',
  tags: [],
}

export default GalleryCard;